<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="$t('pageDescriptions.quickSupport')"
    />

    <v-row>
      <v-col cols="12">
        <v-btn
          href="https://my.anydesk.com/v2/api/v2/custom-clients/downloads/public/1JEHRLA6L6FE/AnyDeskClient-FF.exe"
          color="blue-grey"
          class="ma-2 white--text"
        >
          <v-icon left dark> mdi-microsoft </v-icon>
          <span class="text-capitalize">AnyDesk Windows</span>
        </v-btn>

        <v-btn
          href="https://my.anydesk.com/v2/api/v2/custom-clients/downloads/public/W2HJWWZ2JA8U/AnyDeskClient-FF.dmg"
          color="blue-grey"
          class="ma-2 white--text"
        >
          <v-icon left dark> mdi-apple </v-icon>
          <span class="text-capitalize">AnyDesk MacOS</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
export default {
  name: "QuickSupport",
  components: {
    PageHeader,
  },
  data: () => ({
    pageTitle: "Quick-Support",
    pageDescription: null,
    loading: false,
    selection: 1,
  }),

  created: function () {
    // allow to change company?
    this.$store.commit("companyChangeOption/SET_ALLOWED", true);
  },
};
</script>
